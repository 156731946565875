header{
    width: 100%;
    position: fixed;
    z-index: 9;
}

.header-flex{
    display: flex;
    gap: 40px;
    
    align-items: center;
    padding: 10px 0px;
}

.header-bg {
    background-color: rgba(0,0,0,.9);
  }

.logo-div{

}

.logo-div img{
  height: 70px;
}

.logo-div .small-logo{
    height: 50px;
    
  }

nav{

}

nav ul{
    text-decoration: none;
    display: flex;
    gap: 30px;
    list-style: none;

}

nav ul li{
    text-decoration: none;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}

nav ul li:hover{
    
    color: #27ae60;
    /*border-bottom:solid 1px #27ae60;*/
    
}


.mobile-div{
    background-color: #000;
    display:none;
   padding: 5px 2%;
   
    
    
   }
   
   .mobile-logo{
     height: 50px
   }
   
   .mobile-menu {
      display: none;     
   }
   
   
   .mobile-menu ul {
   
     display: flex;
     flex-direction: column;
     justify-content:center;
     gap: 40px;
     align-items: center;
     text-align: center;
     list-style: none;
     
   }
   
   .mobile-menu ul li{
     color: #fff;
     cursor: pointer;
   }

   
   @media screen and (max-width: 768px) {

    header{
      display: none;
    }

    .mobile-div{
      position: fixed;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      z-index: 9;
     }

     .mobile-menu {
      display:block;

      position: fixed;
      left: -100%;
      top: 60px;
      background-color: rgba(0,0,0,.9);
      width: 100%;
      min-height: 100vh;
      padding:40px;
      z-index: 999;
      transition: .3s;
      box-sizing: border-box;
      overflow: hidden;
}

    .mobile-menu.active {
    left: 0;
    }
    
  }