.footer-section{
    padding: 80px 0px;
}

.footer-section hr{
    margin: 50px 0px;
    border: 1px solid hsl(0, 1%, 16%);
}

.newsletter-div{
    width: 860px;
    margin: 0px auto;
    background-color: #27ae60;
    padding: 30px;
    border-radius: 15px;
}

.newsletter-div h4{
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: #fff;
}

.newsletter-div p{
    font-size: 15px;
    text-align: center;
    color: #fff;
}

.newsletter-form{
    margin-top: 20px;
    display: flex;
    gap: 15px;
    flex-direction: row;
}

.newsletter-form .n-input{
    width: 80%;
}

.newsletter-form .n-input input{
    width: 100%;
    padding: 13px;
    outline: none;
    color: #333;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    /*|border: 1px solid #27ae60;*/
}

.newsletter-form .n-button{
    width: 20%;
}
.newsletter-form .n-button button{
  padding: 13px 30px;
  width: 100%;
  background-color:#fff;
  color:  #27ae60;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}

.footer-menu{

}

.footer-menu ul{
    display: flex;
    gap: 20px;
    justify-content: center;
}

.footer-menu ul li{
    color: #7b807d;
    cursor: pointer;
    font-size: 15px;
}
.footer-menu ul li:hover{
    color: #fff;
}

.copyright{
    margin-top: 40px;
    font-size: 14px;
    color: #7b807d;
    text-align: center;
}

.copyright:hover{
    color: #fff;
}

@media screen and (max-width: 768px) {

    .footer-section{
        padding: 80px 8%;
    }

    .newsletter-div{
        width: 90%;
        margin: 0px auto;
        background-color: #27ae60;
        padding: 20px;
        border-radius: 15px;
    }

    .newsletter-div h4{
        font-size: 25px;
    }

    .newsletter-form{
        margin-top: 20px;
        display: flex;
        gap: 15px;
        flex-direction: column;
    }

    .newsletter-form .n-input{
        width: 100%;
    }

    .newsletter-form .n-button{
        width: 100%;
    }

    .footer-menu ul{
        
        flex-wrap: wrap;
    }


}