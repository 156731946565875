.contact-section{
   padding: 80px 0px;
}

.contact-left{
    flex:1.3;
    padding: 20px;
}

.contact-left h2{
    font-size: 50px;
    font-weight: 700;
    color: #fff;
}

.contact-left h4{
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    margin-top: 20px;
}

.contact-details{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 30px;
}
.c-details-item{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.c-details-item img{
    height: 40px;
}

.c-details-item p{
    font-size: 15px;
    color: #fff;
}

.contact-right{
    flex: 1;
}

.contact-right form{
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid #27ae60;
    border-radius: 10px;
    gap: 30px;
}

.contact-right input{
    width: 100%;
    padding: 15px;
    
    outline: none;
    color: #fff;
    background-color: transparent;
    border: 1px solid #27ae60;
}

.contact-right textarea{
    width: 100%;
    padding: 15px;
    
    outline: none;
    color: #fff;
    background-color: transparent;
    border: 1px solid #27ae60;
}

.contact-right button{
    margin: 10px 0px 0 0;
}


/* ################ MODAL ################ */
.overlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .modalContainer {
    max-width: 600px;
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
    padding: 20px 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    border-bottom: #27ae60 4px solid;
    
  }

  .closeIcon{
    position: fixed;
    top: 3px;
    right: 3px;
    color:#27ae60;
  }

@media screen and (max-width: 768px) {

    .contact-section{
        padding: 80px 8%;
     }

    .contact-section .flex{
        flex-direction: column;
        gap: 30px;
    }

    .modalContainer {
        max-width: 90%;
        margin: 0 auto;
    }

    .modalContainer h2{
        text-align: center;
        font-size: 20px;
    }


}