.clients-section{
  padding: 80px 0px;
}

.client-p{
    font-size: 15px;
    color: #fff;
    line-height: 1.5;
    text-align: center;
}

.clients-list{
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.client{

}

.client img{
    height: 50px;
}

@media screen and (max-width: 768px) {

    .clients-section{
        padding: 80px 7%;
      }


}