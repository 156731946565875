.hero-section{
    width: 100%;
    height: 100vh !important;
    position: relative;
    display: flex;
    flex-direction: column;
    /*justify-content: center;
    align-items: center;
    margin-top: 150px;*/
    gap: 20px;
}

.hero-section::before{
    content: '';
    /*background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../../public/images/hero.jpg') no-repeat center center/cover;*/
    background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../../public/images/herobg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.left{
    width: 45%;
}

.right{
    width: 55%;
}

.right img{
    /* height: 450px; */
    width: 90%;
    margin-top: 180px;
    float: right;
}

.hero-title{
    font-weight: 700;
    margin-top: 250px;
    color:#27ae60;
    font-size: 70px;
    line-height: 0;
    margin-bottom: 50px;
    letter-spacing: 25px;
}

.text{
    margin-top: 30px;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
    text-align: left;
}


@media screen and (max-width: 822px) {


    .hero-section{
        height: auto !important;
        padding: 50px 0px;
        background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../../public/images/herobg.jpg') no-repeat center center/cover;
    }

    .hero-section::before{
        height: auto !important;
        /* min-height: 100vh !important; */
        background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../../public/images/herobg.jpg') no-repeat center center/cover;
    }

    .hero-section .flex{
        width: 100%;
        flex-direction: column;
        gap: 20px;
        padding: 0px 5%
    }

    .left{
        width: 100%;
    }
    
    .right{
        width: 100%;
    }

    

    .right img{
        /* height: 300px; */
        width: 100%;
        margin-top: 20px;
        float: none;
        text-align: center;

    }

    .hero-title{
        font-size: 35px;
        font-weight: 900;
        margin-top: 100px;
        text-align: center;
        color: #32b167;
    }

    .text{
        font-size: 14px;
        color: #fff;
        line-height: 1.5;
        text-align: left;
    }
    
  }

  @media screen and (max-width: 408px) {
    


  }