.about-section{
    padding: 80px 0;
    
}

.a-text{
    font-size: 15px;
    color: #fff;
    line-height: 1.5;
    text-align: center;
}

.about-flex{
    margin-top: 50px;
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    
}

.flex-box-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    gap: 20px;
    background-color: #1f1e1e;
    padding: 25px;
    border-radius: 10px;
}

.flex-box-content img{
    height: 75px;
}

.flex-box-content h3{
    font-size: 25px;
    font-weight: 500;
    color: #fff;
}

.flex-box-content p{
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-align: center;
}

.image-text-div{
    margin-top: 100px;
    
}

.imagebox{
    flex: 1;
    
}

.green-div{
    position: relative;
    width: 350px;
    height: 500px;
    background-color: #27ae60;
}

.image-div{
   position: absolute;
   left: 100px;
   top: 50px;
   width: 400px;
   height: 400px;
   background-color: #1f1e1e;
}

.image-div img{
    width: 100%;
 }

.textbox{
    flex: 1;
    padding-top: 65px;
}

.textbox p{
    text-align: left;
    line-height: 1.5;
    font-size: 15px;
    color: #fff;
    margin-bottom: 25px;
    padding-left: 50px;
}

@media screen and (max-width: 768px) {

    .about-section{
        padding: 80px 8%;
    }
    
    
    .about-flex{
        margin-top: 50px;
        gap: 20px;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .flex-box-content{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        background-color: #1f1e1e;
        padding: 25px;
        border-radius: 10px;
    }
    
    .flex-box-content img{
        height: 75px;
    }
    
    .flex-box-content h3{
        font-size: 25px;
        font-weight: 500;
        color: #fff;
    }
    
    .flex-box-content p{
        font-size: 15px;
        line-height: 1.5;
        color: #fff;
        text-align: center;
    }
    
    .image-text-div{
        margin-top: 100px;
        flex-direction: column;
        
    }
    
    .imagebox{
        width: 100%;
        align-items: center;
        justify-content: center;
        
    }
    
    .green-div{
        width: 230px;
        height: 360px;
    }
    
    .image-div{
       left: 60px;
       top: 50px;
       width: 250px;
       height: 250px
 
    }
    
    .textbox p{
        padding-left: 0px;
        padding: 0 20px;
    }


}