.service-section{
    padding: 80px 0px;
    
}

.text-image{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
}

.s_image-div{
    width: 75%;
    height: 300px;
    /*position: absolute;
    right: 0px;*/
    /*float: right !important;
    background-color: #27ae60;
    border: 0.6px solid #646363;*/
    background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../../../public/images/service.jpg') no-repeat center center/cover;

    /*z-index: -5;*/
    
    
  }

.s_text-div{
 
  margin-bottom: 20px;
}

.s_text-div h3{
    color: #fff;
    font-size: 30px;
    font-weight: 500;
}

.s_text-div p{
    margin-top: 15px;
    color: #fff;
    font-size: 15px;
    line-height: 1.5;
}

.s_service_flex{
    display: flex;
    flex-direction: row;
    padding: 40px 0px;
    justify-content: center;
    align-items: center;
}

.s_left-content{
    flex: 1;
}
.s_right-content{
    flex: 1;
    justify-content:center;
    text-align: center;
}

.s_right-content img{
    width: 300px;
    
    border-radius: 7px;
}


.s_service-list{
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.s_service-item{
    width: 48%;
    /*min-height: 100px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 15px;
    /*background-color: #292929;*/
    margin-bottom: 35px;
}

.s_service-item img{
    width: 40px;
}

.s_service-item h4{
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    
}

.academics-div{

}

.academics-div h3{
    font-size: 30px;
    color: #fff;
    margin-bottom: 15px;
}

.academics-div p{
    font-size: 15px;
    color: #fff;
    line-height: 1.5;
}
.courses-div{
margin-top: 50px;
}
.course-list{
 display: flex;
 gap: 20px;
 justify-content: space-around;
}
.course{
  width: 33.3%;
  background-color: #292929;
  border-radius: 10px;
  overflow: hidden;

}

.course img{
    width: 100%;
    height: auto;
    background-color: #292929;
  
  }

.course p{
    padding :10px;
}


.course-slide-div{
    margin-top: 50px;
}
.course-slide{

background-color: #292929;
  border-radius: 10px;
  overflow: hidden;

}

.course-slide img{
    width: 100%;
    height: auto;
    background-color: #292929;
  
  }

  .course-slide p{
    padding :10px;
}

.attention{
    margin-top: 25px;
    padding: 20px;
    background-color: #27ae60;
    color: #fff;
}

.swiper-button-next, .swiper-button-prev { color: #27ae60; }

.image-swiper-button-prev {
    left: 5px;
    color: #27ae60;
  }
  .image-swiper-button-next {
    right: 5px;
    color: #27ae60;
  }
  .swiper-button-disabled {
    opacity: 0.5;
  }


@media screen and (max-width: 768px) {


    .service-section{
        padding: 80px 5%;
        
    }

    .s_service_flex{
        display: flex;
        flex-direction: column;
        padding: 40px 0px;
    }
    .s_service-list{
    }

    

    .s_service-item{
        width: 48%;
        margin-bottom: 20px;
        padding: 10px;
    }

    .s_service-item h4{
        color: #fff;
        font-size: 16px;
        padding: 0px;
    }

    .text-div{
        width: 320px;
        margin-left: -80px;
        margin-top: 40px;
        
      }

      .text-div h3{
        color: #fff;
        font-size: 25px;
        font-weight: 500;
    }
    
    .text-div p{
        margin-top: 15px;
        color: #fff;
        font-size: 15px;
        line-height: 1.5;
    }
      
  }