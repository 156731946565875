/*@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");*/
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

section{
  overflow: hidden;
}
body {
  /*font-family: "Roboto", sans-serif;
  font-family: 'Poppins', sans-serif;*/
  font-family: 'Varela Round', sans-serif;
  background-color: #0b0b0b;
  /*background: linear-gradient( rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75) ), url('../public/images/generalbg.jpg') no-repeat center right/cover;*/
}

.container{
  max-width: 1080px;
  margin:0px auto;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}

.flex {
  display: flex;
}

.row {
  width: 50%;
  
}
.button {
  padding: 13px 30px;
  background-color:#27ae60;
  color:  #ffffff;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}

.button-outline {
  padding: 13px 30px;
  background-color:transparent;
  color:  #ffffff;
  font-weight: 600;
  border:1px solid #27ae60;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}

.title{
  font-size: 48px;
  font-weight: 700;
  padding-bottom: 3px;
  text-align: center;
  color: #ffffff;
  /*color: #27ae60;
  width: fit-content; 
  /*text-transform: uppercase;
  /*border-bottom: 4px solid #27ae60;*/
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .container{
    width: 100%;
    /*padding: 5%;*/
  }

  .title{
    font-size: 35px;
  }
}