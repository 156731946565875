.consult-section{
    padding: 80px 0px;
}

.c_left{
    flex: 1;
    padding-right: 20px;
}

.c_left img{
    width: 200px;
}

.c_left h3{
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
}

.c_left p{
    font-size: 15px;
    color: #fff;
    line-height: 1.5;
}

.c_right{
    flex: 1;
    text-align: right;
}

.c_right img{
    height: 300px;
}

@media screen and (max-width: 768px) {


    .consult-section{
        padding: 80px 8%;
    }
    
    .consult-section .flex{
        flex-direction: column;
        gap: 30px;
    }

    .c_left{
        flex: 1;
        padding-right: 0px;
        justify-content: center;
        align-items: center;
    }
    
    .c_left img{
        width: 200px;
        margin-bottom: 20px;
    }
    
    .c_left h3{
        font-size: 25px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 20px;
    }
    
    
    .c_right{
        flex: 1;
        text-align: center;
    }
    
    .c_right img{
        height: 220px;
    }


}